.bg-screen {
  z-index: 1000;
  position: absolute;
  width: 100%;
  bottom: 0;
}



.bottomBox {
 

  background-color: #2182b1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.lag-btn {
  width: 13rem;
  height: 2.5rem;
  border-radius: 20px !important;
  color: black !important;
}

.lag-btn2 {
  width: 100% !important;
  height: 42px;
  border-radius: 10px !important;
  color: #fff !important;
  text-transform: none !important;
}

.lag-btn3 {
  width: 13rem;
  height: 42px;
  border-radius: 10px !important;
}

.lag-btn4 {
 
  height: 40px;
  border-radius: 10px !important;
  color: #fff !important;
  text-transform: none !important;
}

.btn-body {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  padding-top: 40px;


}

.btn-body2 {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}





.home-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 0px;
  width: 100%;
}

.MuiButton-outlinedPrimary {
  outline: solid 1px #fff !important;
}