.DeleteModal-body {
    background-color: #fff;
    margin: 1rem;
    border-radius: 10px;
    padding: 1rem;
    position: relative;
    /* top: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .btn-reg-success {
    width: 169px !important;
    height: 48px !important;
    margin-top: 1rem !important;
    border-radius: 15px !important;
  }
  