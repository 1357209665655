.list-card {
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    width: 100%;
    box-shadow: 0px 2.5px 5px rgba(22, 107, 142, 0.25);
}

.first-row {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    /* gap: 7px; */
    margin-bottom: 12px;
}

.second-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding: 10px 0px;
}

.third-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
}

.list-btn1 {
    border: solid 1px #F09630 !important;
    color: #F09630 !important;

}

.list-btn1:focus {
    background-color: unset !important;
    color: #F09630 !important;
}

.listing-typo {
    color: #166B8E;
    font-size: 15px !important;
    font-weight: 700 !important;
}

.listing-typo1 {
    font-size: 12px !important;
    font-weight: 700 !important;
}


.editButton {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: black !important;
    text-transform: none;
    border-bottom: 1px solid black !important;
    border-radius: 6px !important;
    box-shadow: 1px 0px 6px #000000ad !important;
    text-transform: none !important;
}

.calls-typo {
    line-height: 19.36px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.likes-typo {
    line-height: 14.52px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 3px;
}