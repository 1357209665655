.bottom-nav {
    width: 100%;
    height: 45px;
    background-color: gainsboro;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.081);

    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bottom-nav img {
    width: 1.6rem;
}

.bottom-nav li {
    list-style: none;
}

.bottom_main_box {
    position: relative;
    width: 60px;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    bottom: 8px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;


}

.bottom_main_box::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    left: -20px;
    background: gainsboro;
    border-top-right-radius: 21px;
    /* box-shadow: 6px -6px 0 0px #ffffffe8; */
    top: 11px;

}

.bottom_main_box::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    right: -20px;
    background: gainsboro;
    border-top-left-radius: 21px;
    /* box-shadow: -1px -10px 0 0px #fff; */
    top: 11px;

}


.bottom_semi {
    width: 60px;
    height: 40px;
    position: absolute;
    bottom: 0px;
    background: #dcdcdc99;
    border-radius: 0 0 150px 150px;
    left: 10px;
}

.bottom_icon_box {
    position: absolute;
    width: 45px;
    height: 45px;
    background: #2182B1;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 17px;
    bottom: 15px;
    border: 2px solid white;
}