.inp-dropdown-business {
    outline: unset;
    border: unset;
    width: 100%;
    height: 3rem;
    border-radius: 50px;
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
}

.next-btn {
    width: 50px;
    height: 50px;
    background: #0E3A53;
    box-shadow: 0px 4.5283px 9.0566px rgba(40, 78, 120, 0.1);
    border-radius: 14.4906px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.edit-service-btn {
    padding: 0 !important;
    min-width: 0 !important;
    width: 24px;
    height: 24px;
    background-color: rgba(50, 160, 175, 1) !important;
    color: white !important;
    margin-right: 10px !important;
    border-radius: 20px !important;
}

.reg-inp1 {
    outline: unset;
    border: unset;
    width: 100%;
    height: 3rem;
    border-radius: 50px;
    padding: 1rem;
    box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
}

.delete-btn {
    position: absolute !important ;
    width: 24px;
    height: 24px;
    right: 30px;
    top: 19px;
    padding: 0px !important;
    min-width: 0px !important;
    height: 24px;
    width: 24px;
    background: rgba(237, 29, 36, 1) !important;
    color: white !important;
    border-radius: 20px !important;
}