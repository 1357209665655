.modal-body {
  background-color: #fff;
  margin: 1rem;
  border-radius: 24px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;  
  width: 100%;

}

.btn-reg-success {
  width: 169px !important;
  height: 48px !important;
  margin-top: 1rem !important;
  border-radius: 15px !important;
}
