.pro-head {
    width: 100%;
    height: 5rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro-img {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
}

.options-body {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2rem 0px;
    padding: 0px 10px;
}

.profile-inp {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 10px !important;
    box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 1rem !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;

}