.cat-inp {
    width: 91%;
    height: 38px;
    border-radius: 8px;
    overflow: unset !important;
    border: unset;
    border: solid .2px black !important;
    padding: 1rem;
    margin: 1rem;
}

.list-Card {
    width: 91%;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.list-Card-body{
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
    margin-bottom: 8rem;
}