.product-content {
    width: 100%;
    background-color: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 101;
    position: relative;

}

.social-media {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 2.5px 5px rgba(22, 107, 142, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.pro-btn {
    border-radius: 24px !important;
    width: 69px !important;
    height: 32px !important;

}

.typo-name {
    border-bottom-left-radius: 10px ;
    border-bottom-right-radius: 10px ;
    position: absolute ;
    text-align: center !important;
    background: #c5adad ;
    left: 50% ;
    top: 0;
    transform: translate(-50%, 0px) ;
    padding: 0px 6px !important;
    font-size: 12px !important;
    line-height: 15px !important ;
    width: max-content !important;
}

.img-parent {

    position: relative;
    border-radius: 9px;
    overflow: hidden;
    height: 140px;
    width: 90%;
}

.img-child {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 0;
    box-shadow: inset 0 0 10px 6px rgb(165 156 162 / 88%);
    border: none;
    z-index: 1000;
}

.yarl__root {
    z-index: 10000 !important;
}