.completeRegistration-bg-screen {
  height: 100vh;
  position: relative;
}

.completeRegistration-bottomBox {
  z-index: 1000;
  position: absolute;
  
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.completeRegistration-btn {
  width: 13rem;
  height: 3.7rem;
  border-radius: 20px !important;
}
.completeRegistration-btn2 {
  width: 100%;
  height: 3rem;
  font-size: .8rem !important;
  border-radius: 10px !important;
}

.completeRegistration-title {
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.error-message{
  margin: 2px 0px;
  color: red;
}
.completeRegistration-btn-body2 {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.css-1a7t9kz-MuiButtonBase-root-MuiButton-root:focus {
  color: #fff !important;
  background-color: #2182b1 !important;
}

.heading {
  position: relative;
  top: 15rem;
  border-radius: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inp-dropdown {
  width: 5rem;
  height: 3rem;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding: 1rem;
  position: relative;
  left: 0rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
  border: unset;
  outline: unset;
  border-right: solid 1px black;
}

.inp-number {
  outline: unset;
  border: unset;
  width: 77vw;
  height: 3rem;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  padding: 1rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
}

.dropdown-mob-num {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0rem;
  margin-bottom: 10px;
 
}

.completeRegistration-form-body {
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;
}

.reg-inp {
  outline: unset;
  border: unset;
  width: 100%;
  height: 3rem;
  border-radius: 50px;
  padding: 1rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
  margin-bottom: 10px;
}

.reg-textarea{

  outline: unset;
  border: unset;
  width: 100%;
 
  border-radius: 20px;
  padding: 1rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
  margin-bottom: 10px;
}

.completeRegistration-heading{
    position: relative;
    top: 4rem;
    border-radius: 21px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title{
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 17px;
  color: #333333;
  margin-bottom: 10px !important;
}