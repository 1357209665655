.news-head{
    width: 90%;
    height: 2rem;
    border: solid 1px #2182b1;
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
}
.channel-button-class{
    border: solid 1px #2182b1;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 8px;
    padding: 2px;
}