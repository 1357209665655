.service-main {
    height: 40px;
    background: gainsboro;
    box-shadow: 0px 2px 4px 1px #00000087;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-secondary {
    transform: rotate(29deg);
    background: #a2a2ac;
    position: absolute;
    height: 61px;
    left: 40px;
    top: -9px;
    filter: blur(2px);
    width: 3px;
}

.typo{
    width: 100%;
    height: 40px;
    border: 1px solid #184c18;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    color:rgba(33, 130, 177, 1)
}

.service-button-class{
    border: solid 1px #184c18;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 10px;
    padding: 6px;
}