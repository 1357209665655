.listing {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    margin-right: 12px;
    background-color: black;
}
.Box-container{
    display: flex;
    align-items: baseline;
    font-size: 14px;
    font-weight: 500;
}
.typo_old{
    font-size: 16px !important;
    text-decoration: line-through;
}
.typo_container{
    display: flex;
    align-items: center;
    gap:10px
}

.typo_box_old{
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex;

}
.typo_box{
    font-size: 24px !important;
    font-weight: 600 !important;
    display: flex;
    color:rgba(33, 130, 177, 1) !important

}
.typo-new{
    font-size: 24px !important;
    font-weight: 600 !important;
    color:rgba(33, 130, 177, 1) !important

}