.inp-dropdown-business {
    outline: unset;
    border: unset;
    width: 100%;
    height: 3rem;
    border-radius: 50px;
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
    margin-bottom: 10px;
}

.next-btn {
    width: 50px;
    height: 50px;
    background: #0E3A53;
    box-shadow: 0px 4.5283px 9.0566px rgba(40, 78, 120, 0.1);
    border-radius: 14.4906px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.cancel-button{
    width:108px;
    height:48px;
    border-radius: 24px !important;
    border: 2px solid rgba(14, 58, 83, 1) !important;
    color:rgba(14, 58, 83, 1) !important;
    text-transform: none !important;
}
.sure-button{
    width:108px;
    height:48px;
    border-radius: 24px !important;
    border: 2px solid rgba(14, 58, 83, 1) !important;
    color:white !important;
    text-transform: none !important;
    background: rgba(14, 58, 83, 1) !important;
}
.try-button{
    width:169px;
    height: 48px;
    border-radius: 12px !important;
    background: rgba(240, 150, 48, 1) !important;
    color: white !important;
    padding: 11px 27px;
    text-transform: none !important;

}
.success-button{
    background: rgba(14, 58, 83, 1) !important;
    width:169px;
    height: 48px;
    border-radius: 12px !important;
    color: white !important;
    padding: 11px 27px;
    text-transform: none !important;

}
.completeRegistration-form-body > p {
    margin-bottom: 10px;
}
.save-button{
    background-color: rgb(14, 58, 83) !important;
    color: white !important;
    height: 38px;
    text-transform: none !important;
}