.signIn-bg-screen {
  height: 100vh;
  position: relative;
}

.signIn-otp-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.round-inp-body {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}

.signUp-bottomBox {

  z-index: 1000;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.heading {
  position: absolute !important;
  top: 2rem !important;
  border-radius: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round-inp {
  width: 48px;
  height: 48px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 70px;
  border: unset;
  outline: unset;
  display: flex;
  text-align: center;
}