.header {
  width: 100%;
  position: fixed;
  z-index: 101;
  top: 0;
}

.logo-icon-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height:24px
}

.dropdown-search-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
}

.loc-dropdown {
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.603);
}

.radius-dropdown {
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.603);

}

.dropdown-inp {
  outline: unset;
  border: unset;
  width: 85px;
}

.search-box {
  width: 35px;
  height: 35px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.603);

}

.dropdown-opt {
  background-color: #fff;
  outline: unset !important;
  border: unset !important;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0 !important;
    padding-right: 32px !important;
} */