.listing-header{
    width: 100%;
    height: 5rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 1000000;
}

.list-content{
    position: relative;
    margin-top: 5.7rem;
}

.mylist-btn{
    width: 50%;
}
