.signUp-bg-screen {
  position: fixed !important;
  top:0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.signUp-bottomBox {

  z-index: 1000;
  position: absolute;

  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.signUp-btn {
  width: 13rem;
  height: 3.7rem;
  border-radius: 20px !important;
  color: black !important;
}

.signUp-btn2 {
  width: 13rem;
  height: 3rem;
  border-radius: 10px !important;
}


.signUp-btn-body2 {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.css-1a7t9kz-MuiButtonBase-root-MuiButton-root:focus {
  color: #fff !important;
  background-color: #2182b1 !important;
}

.heading {
  position: relative;
  border-radius: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143px;
  height: 138px
}

.heading-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inp-dropdown {
  width: 5rem;
  height: 45px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding: 1rem;
  position: relative;
  left: 0rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25);
  border: unset;
  outline: unset;
  border-right: solid 1px black;
}

.signUp-title {
  padding: 1rem;
  padding-bottom: 0;
}

.signUp-form-body {
  padding: 1rem;
  padding-bottom: 0;
}

.inp-number {
  outline: unset;
  border: unset;
  width: 100%;
  height: 3rem;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  padding: 1rem;
  box-shadow: -2px 0px 5px rgba(22, 107, 142, 0.25)
}

.dropdown-mob-num {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0rem;
  margin-top: 9px;
}

input {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}