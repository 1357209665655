.hide {
    display: none !important;
}

.no-data {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-data-text {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    text-align: center;
}

.public-div {
    width: 100%;

    border-radius: 4px;
    color: #fff;
    text-align: center;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom_container {
    color: #fff;
    font-size: 12px !important;
}

.bottom_container_box {
    display: flex;
    align-items: center;
}

.bottom_container_icon{
    color: #fff;
    font-size: 16px !important;
}
.control-dots{
    visibility: hidden !important;
}
