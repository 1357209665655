.lag-bg-screen {

  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.lag-bottomBox {

  z-index: 1000;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.lag-btn {
  width: 13rem;
  height: 44px !important;
  border-radius: 18px !important;
  color: black;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.lag-btn-new {
  width: 13rem;
  height: 2.5rem;
  border-radius: 10px !important;
}

.lag-btn-body {
  display: flex;
  padding: 1rem;
  gap: 1rem;
}

.lag-btn-body2 {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;

}


.heading-lang {

  width: 143px;
  height: 138px
}

.heading-body {
  position: absolute;
  width: 100%;
  top: 8rem;
}